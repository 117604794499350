@import "../../App.css";

.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    min-height: calc(100vh - 193px);

}

.notFound .column {
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    gap: 32px;
}

.notFound .column img {
    height: auto;
    width: 45vw;
}

.CTAButton {
    color: black;
    padding: 12px 40px;
    border-radius: 100px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    border: 1px solid black;
    transition: all 0.2s;
}

.CTAButton:hover {
    cursor: pointer;
    background: var(--secondary-color);
    color: white;
    border: 1px solid var(--secondary-color);
}

@media screen and (max-width: 900px) {
    .notFound {
        min-height: calc(100vh - 220px);
    }

    .notFound .column {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0px;
        padding: 0px;
        gap: 32px;
    }

    .notFound .column img {
        height: auto;
        width: 75vw;
    }
}