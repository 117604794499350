@import "../../App.css";

.bodayHigh {
  font-size: 56px;
  line-height: 72px !important;
  width: 85vw;
}

.Hero .section {
  display: flex;
}

.heroSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 72px 0px;
}

.NavBar {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0px;
  margin-top: 0 !important;
  margin: 0px 72px;
}

.footer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 32px 72px;

}

.footer span {
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
}

.App .section {
  margin-top: 0px;
}

@media screen and (max-width: 900px) {
  .heroSection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 0px !important;
  }

  .NavBar {
    margin: 0px 20px;
  }

  .bodayHigh {
    font-size: 40px !important;
    line-height: 56px !important;
    width: 90vw !important;
  }

  .footer {
    flex-direction: column;
    gap: 4px;
    padding: 32px 0px;
  }

  .footer span {
    font-size: 18px;
    font-weight: 900;
    line-height: 28px;

  }

  .link img {
    height: 40px;
    width: 40px;
  }

  .link {
    gap: 4px;
  }
}