:root {
  --primary-color: #FBE67F;
  --secondary-color: #4F64D7;
  --on-primary: white;
  --background: #F4F3F0;
  --text-primary: #161616;
  --text-secondary: #45464B;
  --gray: #E0E0E0;
  --surface: #FEFEFE;

}

body {
  margin: 0px;
  /* Default margin */
  padding: 0px;
  /* Default margin */
  color: var(--text-primary)
}




h1 {
  font-size: 80px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: 56px;
  line-height: 72px;
  font-weight: 700;
  padding: 0;
  margin: 0;

}

h3 {
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
  padding: 0;
  margin: 0;

}

h4 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  padding: 0;
  margin: 0;

}

p {
  font-size: 18px;
  line-height: 28px !important;
  padding: 0;
  margin: 0;

}




a {
  font-size: 20px;
  text-decoration: none;
  color: var(--text-primary);
  font-weight: 700;
}

.section {
  margin: 0px 72px 0px 72px;
}



.link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  gap: 8px;
  padding: 0;
  margin: 0;
}

.link:hover {
  color: var(--secondary-color);
}

.selected-link {
  color: var(--text-primary);
  text-decoration: underline;
}

.titleContainer {
  margin-bottom: 40px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: space-between;
  align-items: stretch;
  gap: 24px;
}

button {
  font-size: 20px;
  line-height: 28px;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 9px 25px;
  border: 1px solid var(--text-primary);
  color: var(--text-primary);
  border-radius: 25px;
  transition: all 0.5s ease;
  background-color: var(--background);
}

button:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

.primaryButton {
  background-color: white;
}

.Loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.rowLoading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}

.rowLoading img {
  width: 56px;
}

@media screen and (max-width: 1200px) {
  .section {

    margin: 180px 72px 0px 72px;
  }


}

@media screen and (max-width: 900px) {
  .section {
    margin: 72px 20px 0px 20px !important;
  }

  .row {
    flex-direction: column;
    gap: 28px !important;

  }


  h1 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  h2 {
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    padding: 0;
    margin: 0;

  }

  h3 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    padding: 0;
    margin: 0;

  }

  h4 {
    font-size: 20px;
    line-height: 28px;
    padding: 0;
    margin: 0;

  }

  p {
    font-size: 18px;
    line-height: 24px !important;
    padding: 0;
    margin: 0;

  }

  a {
    font-size: 20px;
    line-height: 28px;
    text-decoration: none;
    color: var(--text-primary);
    font-weight: 700;
  }

  .titleContainer {
    margin-bottom: 0px;
  }

  .link {
    font-size: 20px;
    line-height: 32px;
  }

}