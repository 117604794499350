@import "../../App.css";


.Info .section {
    margin-bottom: 56px;
}

.InfoSection {
    margin-top: 48px !important;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
}

.leftSection .row {
    justify-content: flex-start;
    gap: 16px;
}

.InfoSection .leftSection {
    flex-direction: column;
    margin-top: 32px;
    gap: 16px;
    width: 100%;
}

.InfoSection .column {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.rightSection img {
    height: 40vh;
}

.height {
    width: 50vw;
    font-size: 32px !important;
    line-height: 52px !important;
    margin-bottom: 16px;
}

@media screen and (max-width: 900px) {

    .InfoSection {
        height: 100%;

        display: flex;
        gap: 24px;
        align-items: flex-start;
        flex-direction: column;
    }

    .InfoSection .column {
        display: flex;
        flex-direction: column !important;
        align-items: center;

    }

    .Info .leftSection {
        display: flex;
        flex-direction: column;
        gap: 8px;
        order: 1;
    }

    .rightSection img {
        height: 45vh;
    }

    .height {
        width: 100%;
        font-size: 24px !important;
        line-height: 42px !important;
    }
}