@import "../../App.css";




.process .section .column {
    flex: 1;
    gap: 72px;
}

.process .section .row {
    gap: 72px;
}

.skills {
    background-color: white;
    background-image: url('../../Img/point.svg');
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    gap: 72px;
    padding: 72px;
    height: 100%;
    border-radius: 32px;
    border: 1px solid var(--text-primary);
}




.serviceContainer {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    aspect-ratio: 3/2;
    background-color: #FBE67F;
    box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.12);
}

.serviceContainer img {
    width: 56px;
    height: 56px;

}

.card-title {
    margin-bottom: 12px;
}


@media screen and (max-width: 900px) {
    .process .section {
        flex: 1;
        padding: 32px 0px !important;
    }

    .process {
        padding: 24px 0px !important;
    }

    .process .section .column {
        flex: 1;
        gap: 32px !important;
    }

    .process .section .row {
        gap: 32px !important;
    }

    .card-title {
        margin-bottom: 4px;
    }

    .serviceContainer {
        display: flex;
        flex-direction: column;
        width: calc(100% - 48px) !important;
        padding: 24px;
        aspect-ratio: 3/2;
        background-color: #FBE67F;
        box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.12);
    }

    .skills {

        border-radius: 16px;
        padding: 24px;
        gap: 24px;

    }

}